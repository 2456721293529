@tailwind base;

@import 'scss/font';
@import 'scss/iconfont';
@import 'scss/base';
@import 'scss/base/forms';
@import 'scss/base/typo';

@import 'scss/vendor/autocomplete';
@import 'scss/vendor/flatpickr';

@import 'scss/components/loader';
@import 'scss/components/accordion';
@import 'scss/components/modal';
@import 'scss/components/dropdown';
@import 'scss/components/gpdr';
@import 'scss/components/actions';
@import 'scss/components/search';
@import 'scss/components/flatpickr';

@import 'scss/sections/gallery';
@import 'scss/sections/header';
@import 'scss/sections/slider';


@tailwind components;

@tailwind utilities;
