.richText {
    font-size: 1rem;
    line-height: 1.55;

    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.5;
        margin-bottom: 0.5rem;
        margin-top: 2rem;
        font-weight: 600;
        font-size: 1.8rem!important;
    }

    strong, b {
      @apply text-primary;
    }

    img {
        @apply rounded-xl;
        height: auto;
        display: block;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        max-width: 100% !important;
    }

    p,
    ul {
        margin-bottom: 1rem;
        line-height: 1.5;
    }

    li {
        margin-bottom: 0.5rem;
    }


}
