.js--more-show {
  @apply transition-all;

  &.active {
    -webkit-box-orient: horizontal;
  }
}


.favoriteAdded {
  i {
    &:before {
      content: "\ee0a",
    }
  }
}
