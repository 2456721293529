.finder-form {

  .flatpickr-wrapper {
    width: 100%;
  }

  input {
    @apply cursor-pointer;

    &::placeholder {
      opacity: 1;
      @apply text-black;
    }
  }
}
