.c-Dropdown {
  position: relative;

    &__trigger {
      cursor: pointer;
    }

    &__content {
      @apply bg-white;
      padding: 0;
      -webkit-overflow-scrolling: touch;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      transition: all ease-out .2s;
      box-shadow: none;
      top: 4rem;
      right: 0;
      z-index: 50;
      width: 16rem;
      box-shadow: 0 0 30px rgba(0, 0, 0, .2);
      transform: scale(.9);
      transform-origin: top;

      &--topleft {
        transform-origin: top left;
      }

      &--topright {
        transform-origin: top right;
        right: 0!important;
        left: auto!important;
      }

      @media (min-width: 567px) {
        left: 0;
        right: auto;
      }

      &--villas {
        width: 90vw;
        @media (min-width: 567px) {
          width: 45rem;
        }
      }

      &--full {
        width: 100%;
      }

      &--wide {
        width: 53.5rem;
      }

      &--share {
        padding: 0.5rem 1rem;
      }

      &--mob-top {
          top: auto!important;
          bottom: 2.5rem;
      }

    }

    .icon-dropdown {
      transform-origin: center;
    }

}

.js--dropdown-open {
    .c-Dropdown__content {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
      transition: all ease-out .2s;
    }

    .icon-dropdown {
      transform: rotate(-180deg);
      transform-origin: center;
    }
}
