.c-Slide, .c-Image {
  transition: all .5s ease-in-out;
  z-index: -1;
  opacity: 0;

  &.active {
    z-index: 1;
    visibility: visible!important;
    opacity: 1;
  }
}
