$color_1: rgb(234 174 61 / var(--tw-text-opacity));

.autocomplete {
  position:relative;
}
.autocomplete ul,
.autocomplete li {
  list-style:none;
  margin:0;
  padding:0;
}
.autocomplete .autocomplete-list {
  @apply text-black bg-white shadow;
  position:absolute;
  top: 100%;
  right:0;
  margin:0;
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 0.5rem;
  min-height: 14.5rem;
  max-height: 14.5rem;
  list-style:none;
  width:100%;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  z-index: 30;
  padding-bottom: 1rem;


  @media (min-width: 640px) {
    height: auto;
    font-size: 1.15rem;
    line-height: 1.25;
    max-height: 16.5rem;
    min-height: auto;
  }

}
.autocomplete .autocomplete-group {
  position:relative;
  padding: 0 0;

  h4 {
    @apply text-black;
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    margin-top: 0.75rem;
    margin-left: 1.25rem;

    @media (min-width: 567px) {
      font-size: 0.75rem;
    }
  }
}
.autocomplete .autocomplete-group-header {
  position:absolute;
  top: 16px;
  display: none;
  left: 10px;
  font-weight:bold;
}
.autocomplete .autocomplete-item {
  cursor:pointer;
  padding: 0.5rem 1.25rem;

  @media (min-width: 567px) {
    padding: 0.5rem 1.25rem;
  }

  &:last-child {
    margin-bottom: 0.5rem;
  }

  &:first-child {

  }
}

.autocomplete .autocomplete-item.active,
.autocomplete .autocomplete-item:hover {
  @apply bg-primary-light; 
  color:$color_1;
}
.autocomplete .autocomplete-item-last {
  font-weight:bold;
  text-align:center;
  padding:10px;
}
