.accordion {
    cursor: pointer;
    width: 100%;
    border: none;
    outline: none;

    &.active {

      .accordion__label-open {
          display: none;
        }

      .accordion__label-close {
          display: inline!important;
        }

      .accordion__arrow {
        transform: rotate(-180deg);
        transform-origin: center;
      }

    }


}


.panel {
    max-height: 0;
    overflow: hidden;
    padding: 0;
}


.accordion.active + .panel::not(.panel-nopadding) {
  padding-top: 0.5rem;
}
