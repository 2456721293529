button[disabled], .is--disabled {
  cursor: not-allowed!important;
  opacity: 0.5!important;
}

.placeholder-opacity-100 {
  &::placeholder {
    @apply text-white;
    opacity:1!important
  }
}

#i_surname {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
    user-select: none;
}

input.formFieldError {
  border-color: red;
}

label.error {
    display: none !important;
    text-color: red;
}

input.formFieldError+label.error {
    display: block !important;
}

[data-validate-for],
.too-many {
    color: red;
    margin-top: 0.5rem;
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 600;
}

button > * {
  pointer-events: none;
}
