.finder-form .flatpickr-wrapper {
    width: 100%;
}
.flatpickr-day::after {
    content: attr(data-price);
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
    font-weight: 600;
    color: gray; /* Optionally style the data-price text */
}
.dayContainer{
    gap:5px;
    padding-bottom: 20px;
}