/* The Modal (background) */
.c-Modal {
    background-color: rgba(0,0,0,0.6);
    display: none;
    position: fixed;
    z-index: 10001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    @media (min-width: 567px) {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    &__inner {
        @apply bg-white shadow;
        border-radius: 1rem;
        padding: 1rem 1rem 2rem;
        margin: 1.5rem;
        width: calc(100% - 3.5rem);
        position: relative;

        @media (min-width: 567px) {
          width: calc(100% - 2rem);
          padding: 3rem 3.5rem 3.5rem;
          margin: 1rem auto;
        }

        @media (min-width: 800px) {
          max-width: 40rem;
        }

        p {
          font-size: .9rem;
        }
    }

    &__title {
      font-size: 1.45rem;
      margin-bottom: 1rem;
      font-weight: 500;
    }

    &__content {
      @apply text-sm ;
      line-height: 1.5;
    }

    &__text {
      @apply text-sm ;
      margin-bottom: 1rem;
      padding-right: 2rem;
    }


}

.list-cookies {

  &__actions {
    @apply flex justify-between;
    margin-top: 1.5rem;
  }

  .single-cookie {

    &__label {
      font-weight: 600;
      font-size: 0.75rem;
      margin-bottom: 0.75rem;
      margin-top: 0.25remrem;
      text-transform: uppercase;
      letter-spacing: 1px;
      display: block;
    }
    &__switch {
      padding-left: 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;

      @media (min-width: 567px) {
        padding-left: 2rem;
      }

      &--disabled {
        label {
          @apply text-opacity-50;
          cursor: not-allowed;
        }
      }
    }

  }
}

.btn--save {
  @apply border border-secondary;
  height: 3rem;
  padding: 0.75rem 1.5rem 0.65rem;
  background-color: transparent;
  transition: all ease 0.3s;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: color 0.4s cubic-bezier(0.47, 0.17, 0.17, 0.58);

    span {
      z-index: 1;
      position: relative;
    }

      &::before {
        @apply bg-secondary;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 100%;
        transition: right 0.6s cubic-bezier(0.47, 0.17, 0.17, 0.58);
      }

    @media (min-width: 567px) {
      &:hover {
        @apply text-white;
        background-color: transparent;

        &::before {
          right: 0;
        }
      }
    }

}

.c-CookiesBar {
    @apply bg-white shadow;
    position: fixed;
    overflow: hidden;
    box-sizing: border-box;
    line-height: 1.5em;
    z-index: 999;
    right: 0;
    bottom: 0;
    opacity: 1;
    transition: opacity 1s ease;
    padding: 1rem 1.5rem 2rem;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;

    p {
      font-size: .9rem;
    }

    @media (min-width: 567px) {
      padding: 2.5rem;
      max-width: 40rem;
      right: 1.5rem;
      bottom: 1.5rem;
      box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
    }

    &__description {
      @apply text-sm mb-6;
    }

    &__btns {
      @apply flex justify-between items-center;
    }

    &__btn {
      @apply text-sm;
    }

}
