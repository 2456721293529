html, body {
  @apply text-black cursor-default font-sans;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

h1, .text-h1 {
  @apply text-4xl lg:text-5xl xl:text-6xl font-semibold tracking-tight leading-tight
}

h2, .text-h2 {
  @apply text-3xl lg:text-5xl font-semibold tracking-tight leading-tight
}

h3, .text-h3 {
  @apply text-3xl font-semibold tracking-tight leading-tight
}

h4, .text-h4 {
  @apply text-3xl font-medium tracking-tight leading-tight
}

h5, .text-h5 {
  @apply text-xl lg:text-2xl font-semibold leading-tight
}

h6, .text-h6 {
  @apply text-lg xl:text-xl font-medium leading-tight
}

p {
  @apply text-base lg:text-xl tracking-wide leading-tight
}

small {
  @apply text-sm md:text-base tracking-wide leading-tight
}

.btn {
  @apply text-base md:text-xl font-semibold
}

.btn-small {
  @apply text-sm lg:text-base font-medium px-7 py-2 lg:py-3
}

.h_600 {
  @media (min-width: 567px) {
    height: 600px;
  }
}

input, select, textarea {
  &:focus {
    @apply outline-none
  }
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.container {
  @apply px-5
}

.card-object {
  &:hover {
    @apply shadow;
    .card-favorite {
      @apply opacity-100
    }
  }
  .card-img {
    height: 500px;

    @media screen and (max-width: 1500px) {
      height: 400px;
    }

    @media screen and (max-width: 1023px) {
      height: 340px;
    }
  }
}
.blog-img {
  height: 520px;
  @media screen and (max-width: 1023px) {
    height: 350px;
  }
}

.radio-box {
  .radio-wrapper {
    @apply block px-8 py-7 cursor-pointer select-none
  }
  .checkmark {
    @apply block border-2 border-black w-5 h-5 relative rounded-full
  }
  input:checked + .radio-wrapper .checkmark {
    &:after {
      top: 3px;
      left: 3px;
    }
    @apply after:content-[''] after:absolute after:w-2.5 after:h-2.5 after:bg-black after:rounded-full
  }
}

.custom-checkbox {
  @apply flex items-center gap-2 mb-2;
  .checkmark {
    min-width: 1rem;
    @apply block border-2 border-black w-4 h-4 relative cursor-pointer
  }
  input:checked + .checkmark {
    @apply after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:w-2 after:h-2 after:bg-black
  }
}

.checkbox-wrapper {
  .checkmark {
    @apply flex cursor-pointer border border-primary rounded-full text-primary whitespace-nowrap select-none font-medium;
    .icon-close-line {
      @apply hidden
    }
  }
}

.checkbox-wrapper {
  input:checked + .checkmark {
    @apply bg-primary text-white border-primary;
    .icon-close-line {
      @apply lg:block
    }
    .icon-add-line {
      @apply hidden
    }
  }
}

.object-img {
  height: calc(100vh - 250px);
}

.tab-nav {
  .active {
    @apply bg-silver font-medium
  }
}

.switch {
  @apply relative inline-block w-14 h-9;
  input {
    @apply opacity-0 w-0 h-0;
  }
  .slider {
    @apply absolute cursor-pointer rounded-full top-0 left-0 right-0 bottom-0 bg-gray transition-all before:absolute before:content-[''] before:w-6 before:h-6 before:left-1.5 before:bottom-1.5 before:bg-white before:rounded-full before:duration-300 before:transition-all
  }
  input:checked + .slider {
    @apply bg-primary before:translate-x-5
  }
}

table {
  @apply w-full;
  td {
    @apply py-1 whitespace-nowrap
  }
  thead {
    @apply opacity-50
  }
  tbody {
    @apply text-xl
  }
}

.top-discount {
  @media screen and (max-width: 1023px) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(80vw - 200px)
  }
}

.nav-dropdown {
  width: 50rem;
  @media screen and (max-width: 1023px) {
    @apply w-full
  }
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.bg-inherit{
  --tw-bg-opacity: 1;
  background-color: inherit;
}

.js--finder {
  .ui-datepicker-inline {
    width: 100% !important;
  }
}

.home_header {
  @media screen and (max-height: 880px) {
    height: unset !important;
  }
}