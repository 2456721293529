.main-header {
  z-index: 40;
}

.main-topbar {
  z-index: 41;
}

.page-home {
  .main-header {
    @apply absolute w-full top-11;

    .header-logo {
      display: none;
    }
    
    .header-logo-wrap {
      @media (max-width: 900px) {
        display: none;
      }
    }

    > nav {
      @apply bg-transparent;
    }
  }
}

body:not(.page-home) {
  .main-header {
    @apply relative bg-primary;
  }
}

.c-Slide, .c-Image {
  @apply transition-all;
  z-index: -1;
  opacity: 0;

  &.active {
    z-index: 1;
    visibility: visible!important;
    opacity: 1;
  }
}
